import { hostConfig } from "../config";
import { inputFormat } from "../utils/common";
import cookie from "react-cookies";

const responseHandler = (response) => {
  if (response.status === 401) {
    cookie.remove("adminToken");
    // window.location.reload();
    window.location.href = "/home/dashboard";
  } else if (response.status === 200) {
    return response;
  } else if (response.status === 409) {
    return { error: response?.message || "Email is already Exist" };
  } else {
    return false;
  }
};

const errorHandler = () => {
  return false;
};

const getListByApi = (requestUrl, params, isEvent) => {
  let getParams = "?";

  const token = cookie.load("adminToken");
  //check all the params
  if (
    params?.searchTerm &&
    params?.searchKey &&
    params?.searchKey !== undefined &&
    params?.searchTerm !== undefined
  ) {
    if (params.searchTerm !== "date") {
      getParams += `&${params.searchTerm}=${params.searchKey}`;
    }
  }

  if (params && params.pageLimit && params.pageLimit !== undefined)
    getParams += `&limit=${params.pageLimit}`;

  if (params && params.from_date && params.from_date !== undefined)
    getParams += `&from_date=${params.from_date}`;

  if (params && params.to_date && params.to_date !== undefined)
    getParams += `&to_date=${params.to_date}`;

  if (params && params.title && params.title !== undefined)
    getParams += `&title=${params.title}`;

    // if (params && params.isEdited && params.isEdited !== undefined)
    // getParams += `&isEdited=${params.isEdited}`;

  if (params && params.task && params.task !== undefined)
    getParams += `&task=${params.task}`;

  if (params && params.limit && params.limit !== undefined)
    getParams += `&limit=${params.limit}`;
  if (params && params.lastKey !== null && params.lastKey !== undefined)
    getParams += `&lastKey=${params.lastKey}`;

  if (
    params &&
    params.search &&
    params.search !== " " &&
    params.search !== null
  )
    getParams += `&search=${params.search}`;
  if (
    params &&
    params.eventId &&
    params.eventId !== " " &&
    params.eventId !== null
  )
    getParams += `&eventId=${params.eventId}`;
  if (
    params &&
    params.checked &&
    params.checked !== " " &&
    params.checked !== null
  )
    getParams += `&checked=${params.checked}`;
  if (
    params &&
    params.category &&
    params.category !== " " &&
    params.category !== null
  )
    getParams += `&category=${params.category.toLowerCase()}`;
  if (params && params.closed !== null && params.closed !== undefined)
    getParams += `&closed=${params.closed.toLowerCase()}`;
  if (params && params.isEdited !== null && params.isEdited !== undefined)
    getParams += `&isEdited=${params.isEdited}`;
  if (params && params.method !== null && params.method !== undefined)
    getParams += `&method=${params.method.toLowerCase()}`;
  if (params && params.fromDate !== null && params.fromDate !== undefined)
    getParams += `&from_date=${inputFormat(params.fromDate)}`;
  if (params && params.toDate !== null && params.toDate !== undefined)
    getParams += `&to_date=${inputFormat(params.toDate)}`;
  if (params && params.approval !== null && params.approval !== undefined)
    getParams += `&approval=${params.approval}`;
  if (params && params.modeType !== null && params.modeType !== undefined)
    getParams += `&modeType=${params.modeType}`;
  if (params && params.status !== null && params.status !== undefined)
    getParams += `&status=${params.status}`;
  if (params && params.campaignId && params.campaignId !== undefined)
    getParams += `&campaignId=${params.campaignId}`;
  if (params && params.charityId && params.charityId !== undefined)
    getParams += `&charityId=${params.charityId}`;
  if (params && params.mode_type && params.mode_type !== undefined)
    getParams += `&mode_type=${params.mode_type}`;
  if (params && params.previous && params.previous !== undefined)
    getParams += `&previous=${params.previous}`;
  if (params && params.userId && params.userId !== undefined)
    getParams += `&userId=${params.userId}`;
  if (params && params.next && params.next !== undefined)
    getParams += `&next=${params.next}`;
  if (params && params.previous && params.previous !== undefined)
    getParams += `&previous=${params.previous}`;

  if (token) {
    return fetch(
      `${
        isEvent === "isEvent" ? hostConfig.EVENT_API_URL : hostConfig.API_URL
      }${requestUrl}${getParams}`,
      {
        method: "GET",
        mode: "cors",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      }
    )
      .then((response) => {
        return responseHandler(response);
      })
      .then((result) => {
        return result.json();
      })
      .catch((error) => {
        errorHandler(error);
      });
  }
};

const viewDataByApi = (requestUrl, dataId, isEvent, params) => {
  const token = cookie.load("adminToken");
  //check all the params

  let getParams = "?";

  if (params && params.approval !== null && params.approval !== undefined)
    getParams += `&approval=${params.approval}`;
  if (params && params.modeType !== null && params.modeType !== undefined)
    getParams += `&modeType=${params.modeType}`;
  if (params && params.status !== null && params.status !== undefined)
    getParams += `&status=${params.status}`;

  return fetch(
    `${
      isEvent === "isEvent" ? hostConfig.EVENT_API_URL : hostConfig.API_URL
    }${requestUrl}/${dataId}${getParams}`,
    {
      method: "GET",
      mode: "cors",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    }
  )
    .then((response) => {
      return responseHandler(response);
    })
    .then((result) => {
      return result.json();
    })
    .catch((error) => {
      errorHandler(error);
    });
};

const viewUserDataByApi = (requestUrl, params) => {
  const token = cookie.load("adminToken");
  let getParams = "?";
  //check all the params
  if (params && params.pageLimit && params.pageLimit !== undefined)
    getParams += `limit=${params.pageLimit}`;
  if (params && params.modeType && params.modeType !== undefined)
    getParams += `&mode=${params.modeType}`;
  if (params && params.lastKey !== null && params.lastKey !== undefined)
    getParams += `&lastKey=${params.lastKey}`;
  if (params && params.verified !== undefined)
    getParams += `&verified=${params.verified}`;

  return fetch(
    `${hostConfig.API_URL}${requestUrl}/user/${params.userId}${getParams}`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    }
  )
    .then((response) => {
      return responseHandler(response);
    })
    .then((result) => {
      return result.json();
    })
    .catch((error) => {
      errorHandler(error);
    });
};

const postDataApi = (requestUrl, params, isEvent) => {
  const token = cookie.load("adminToken");

  return fetch(
    `${
      isEvent === "isEvent" ? hostConfig.EVENT_API_URL : hostConfig.API_URL
    }${requestUrl}`,
    {
      method: "POST",
      mode: "cors",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(params),
    }
  )
    .then((response) => {
      return responseHandler(response);
    })
    .then((result) => {
      return result.json();
    })
    .catch((error) => {
      errorHandler(error);
    });
};

const putDataApi = (requestUrl, params, id, isEvent) => {
  const token = cookie.load("adminToken");
  let getParams = "?";
  // if (params && params?.campaignId && params?.campaignId !== undefined)
  //   getParams += `campaignId=${params?.campaignId}`;
  // if (params && params?.eventId && params?.eventId !== undefined)
  //   getParams += `eventId=${params?.eventId}`;
  return fetch(
    `${
      isEvent === "isEvent" ? hostConfig.EVENT_API_URL : hostConfig.API_URL
    }${requestUrl}/${id}${getParams}`,
    {
      method: "PUT",
      mode: "cors",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(params),
    }
  )
    .then((response) => {
      return responseHandler(response);
    })
    .then((result) => {
      return result.json();
    })
    .catch((error) => {
      errorHandler(error);
    });
};

const putDataNotificationApi = (requestUrl, params, id, isEvent) => {
  const token = cookie.load("adminToken");
  let getParams = "?";
  if (params && params?.campaignId && params?.campaignId !== undefined)
    getParams += `campaignId=${params?.campaignId}`;
  if (params && params?.eventId && params?.eventId !== undefined)
    getParams += `eventId=${params?.eventId}`;
  return fetch(
    `${
      isEvent === "isEvent" ? hostConfig.EVENT_API_URL : hostConfig.API_URL
    }${requestUrl}/${id}${getParams}`,
    {
      method: "PUT",
      mode: "cors",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(params),
    }
  )
    .then((response) => {
      return responseHandler(response);
    })
    .then((result) => {
      return result.json();
    })
    .catch((error) => {
      errorHandler(error);
    });
};


const deleteDataApi = (requestUrl, id, isEvent) => {
  const token = cookie.load("adminToken");
  return fetch(
    `${
      isEvent === "isEvent" ? hostConfig.EVENT_API_URL : hostConfig.API_URL
    }${requestUrl}/${id}`,
    {
      method: "DELETE",
      mode: "cors",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    }
  )
    .then((response) => {
      return responseHandler(response);
    })
    .then((result) => {
      return result.json();
    })
    .catch((error) => {
      errorHandler(error);
    });
};

const getReporBytUser = (requestUrl, id) => {
  const token = cookie.load("adminToken");
  let getParams = "?";
  return fetch(`${hostConfig.API_URL}${requestUrl}/user/${id}${getParams}`, {
    method: "GET",
    mode: "cors",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => {
      return responseHandler(response);
    })
    .then((result) => {
      return result.json();
    })
    .catch((error) => {
      errorHandler(error);
    });
};

export {
  getListByApi,
  viewDataByApi,
  viewUserDataByApi,
  postDataApi,
  putDataApi,
  deleteDataApi,
  getReporBytUser,
  putDataNotificationApi
};
